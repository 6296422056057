.margin {
    margin: 0 auto;
}

.table {
    width: 80%;
}

.name-hidden {
    border: none;
    font-size: xx-large;
    text-align: center;
    margin: 40px 0px 20px 0;
}

.price-hidden {
    border: none;
    font-size: xx-large;
    text-align: center;
    margin: 20px 0;
}
