.content {
  padding: 1rem;
}

span {
  font-size: 16px;
}

.selected {
  color: blue;
}
