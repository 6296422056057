
.avatar {
    width:30%;
}

.info {
    width: 70%;
    text-align: left;
}

.circle {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border-radius: 50%;
}


img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}