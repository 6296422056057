/* Custom CSS for Table */
.table-container {
    width: 100%;
    overflow-x: auto;
}
.custom-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
}
.custom-table th,
.custom-table td {
    padding: 12px 15px;
    text-align: center;
}
.custom-table thead th {
    background-color: #343a40;
    color: #ffffff;
}
.custom-table tbody tr:nth-child(even) {
    background-color: #e9ecef;
}
.custom-table tbody tr:hover {
    background-color: #ced4da;
}
