._circle {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    width: 300px;
    padding: 30px 0;
    margin: 0 auto;
    border-radius: 15px;
    font-size: 24px;
    color: white;
    margin: auto 30px;
}

._flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

._flex table {
    box-shadow: 0px 0px 10px rgba(107, 107, 107, 0.491);
    border-radius: 8px;
}

.footer {
    background-color: black;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-evenly;
}

.footer * {
    color: darkgreen;
    font-size: 36px;
    font-family: monospace;
}

.statistic {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    margin: 30px auto;
    align-items: center;
    justify-items: center;
    align-content: center;
    padding-right: 10px;
}

.hr {
    background-color: rgb(44, 44, 44);
    padding: 1px;
}

@media (max-width: 768px) {
    .flex {
        display: block;
    }
    .flex div {
        display: grid;
        grid-template-columns: 1fr;
        padding-right: 0px;
        margin: 0px auto;
    }
}
