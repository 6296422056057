.grid-list {
    display: grid;

    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    gap: 0px;
    height: 100%;
    grid-template-areas:
        ". . . . . . . . . . ."
        ". . . . . . . . . . ."
        ". . . . . . . . . . .";
}

.tablerow {
    cursor: pointer;
    transition: 0.2s;
    height: 27px;
    box-sizing: border-box;
}

.tablerow:hover {
    color: rgb(0, 146, 255);
    text-decoration: underline;
}

.margin {
    margin: 0 auto;
}

._margin {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}
.table {
    width: 80%;
}

.grid-item {
    background: #fefe;
}

@media (max-width: 768px) {
    .flex {
        display: grid;
        justify-items: center;
        gap: 5px;
        grid-template-columns: 50% 50%;
    }

    .flex .dashboard-card {
        width: 180px !important;
    }

    .center > div {
        width: 100% !important;
    }

    .ant-grid {
        display: grid;
        margin: 0 !important;
        justify-items: center;
        grid-template-columns: 50% 50%;
    }

    .ant-grid div {
        min-width: 100% !important;
        min-height: 140px !important;
    }
}

.card-category {
    padding: 30px 10px;
    text-align: center;
    font-size: 24px;
    background-color: rgba(
        0,
        0,
        0,
        0.7
    ); /* Semi-transparent black background */
    color: #fff; /* White text color for contrast */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for a subtle 3D effect */
    font-size: 1.2em; /* Slightly larger font for readability */
    margin: 10px;
}
