.seller {
    background-color: rgb(203, 203, 203);
    margin: 10px;
    padding: 20px;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

a {
    transition: 0.2s;
}

a:hover {
    color: rgb(0, 146, 255);
    font-size: 20px;
    text-decoration: underline;
}
