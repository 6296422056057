.table {
    font-size: 11px;
}
.table-container {
    display: flex
}

.table {
    margin: 0 auto;
}

.table table {
     border-collapse: collapse;
     height: auto;
 }

 .table th,
 .table td {
     border: 1px solid black;
     padding: 5px;
 }

 .table th {
     background-color: #f2f2f2;
 }

.table tr:nth-child(even) {
     background-color: #dddddd;
 }