.card-login {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 15px;
  width: 30%;
  height: 300px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
}
.form-login {
  padding: 20px;
}
.btn-login {
  float: right;
}

@media only screen and (max-width: 600px) {
  .card-login {
    width: 80%;
    height: 350px;
  }
}
