.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .navbar {
    display: none;
  }
}
